<template>
<div class="card-box-full">
  <div class="card-box-header">
    User Accounts
  </div>


  <!-- ----------------------------------------------------------------- -->
  <div v-if="errorMessage">
    <div class="alert alert-danger" style="margin-top: 15px; margin-bottom: 5px" role="alert">{{ errorMessage }}</div>
  </div>

  <!-- ----------------------------------------------------------------- -->
  <div v-if="isRefreshing">
    <div class="spinner-border spinner-border-sm text-primary" role="status" style="margin: 13px 0px 0px 10px">
      <span class="sr-only"></span>
    </div>
  </div>

  <!-- ----------------------------------------------------------------- -->
  <div v-if="activeAccounts.length > 0">

    <CTabs variant="tabs" :active-tab="0" style="margin-top: 15px">
      <CTab title="Active">
        <div class="tab-box" style="padding: 10px">

          <table class="table table-responsive-sm" style="margin-top: 10px; margin-bottom: 0px;">
            <thead>
              <tr style="font-size: 13px; border: 2px solid transparent">
                <th></th>
                <th style="text-align:left">Name</th>
                <th style="text-align:left">Email</th>
                <th style="text-align:left">Last Seen</th>
                <th style="text-align:center"></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="account in activeAccounts" style="font-size: 13px" class="clickable-row" @click="clickedAccount(account)">
                <td>
                  <img :src="account.picture" class="account-picture" />
                </td>
                <td>{{ account.name }}</td>
                <td>{{ account.email }}</td>
                <td>
                  <timeago :datetime="account.last_seen"></timeago>
                </td>
                <td align="center">
                  <div v-if="isAdmin()">
                    <span v-if="account.is_admin" class="badge badge-pill badge-primary">
                      ADMIN
                    </span>
                    <span v-else class="badge badge-pill badge-dark">
                      VIP
                    </span>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>

        </div>
      </CTab>
      <CTab title="Deactivated">
        <div class="tab-box" style="padding: 10px">

          <table class="table table-responsive-sm" style="margin-top: 10px; margin-bottom: 0px;">
            <thead>
              <tr style="font-size: 13px; border: 2px solid transparent">
                <th></th>
                <th style="text-align:left">Name</th>
                <th style="text-align:left">Email</th>
                <th style="text-align:left">Last Seen</th>
                <th style="text-align:center"></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="account in deactivatedAccounts" style="font-size: 13px" class="clickable-row" @click="clickedAccount(account)">
                <td>
                  <img :src="account.picture" class="account-picture" />
                </td>
                <td>{{ account.name }}</td>
                <td>{{ account.email }}</td>
                <td>
                  <timeago :datetime="account.last_seen"></timeago>
                </td>
                <td align="center">
                  <div v-if="isAdmin()">
                    <span v-if="account.is_admin" class="badge badge-pill badge-primary">
                      ADMIN
                    </span>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>

        </div>
      </CTab>
    </CTabs>
  </div>

  <CModal :title="selectedAccount.name" :show.sync="showAccount">

    <div v-if="errorMessageAccount">
      <div class="alert alert-danger" style="margin: -5px 0px 10px 0px" role="alert">{{ errorMessageAccount }}</div>
    </div>

    <div class="rounded-box">

      <table width="100%">
        <tr>
          <td align="left">
            <div v-if="selectedAccount.is_deactivated">
              <h5>Account is deactivated</h5>
            </div>
            <div v-else>
              <h5>Account is active</h5>
            </div>
          </td>
          <td align="right">
            <div v-if="selectedAccount.is_deactivated">
              <CButton color="success" style="width: 110px" @click="clickedDeactivate(selectedAccount, false)" :disabled="isUpdatingAccount">
                Activate
              </CButton>
            </div>
            <div v-else>
              <CButton color="danger" style="width: 110px" @click="clickedDeactivate(selectedAccount, true)" :disabled="isUpdatingAccount">
                Deactivate
              </CButton>
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="2" height="5"></td>
        </tr>
        <tr>
          <td align="left">
            <div v-if="selectedAccount.is_admin">
              <h5>Account is an admin</h5>
            </div>
            <div v-else>
              <h5>Account is not an admin</h5>
            </div>
          </td>
          <td align="right">
            <div v-if="selectedAccount.is_admin">
              <CButton color="danger" style="width: 110px" @click="clickedMakeAdmin(selectedAccount, false)" :disabled="isUpdatingAccount">
                Remove
              </CButton>
            </div>
            <div v-else>
              <CButton color="secondary" style="width: 110px" @click="clickedMakeAdmin(selectedAccount, true)" :disabled="isUpdatingAccount">
                Make Admin
              </CButton>
            </div>
          </td>
        </tr>
      </table>

    </div>

    <template #footer>

      <span>
        <div v-if="isUpdatingAccount" class="spinner-border spinner-border-sm text-primary" role="status">
          <span class="sr-only"></span>
        </div>
      </span>

    </template>
  </CModal>

</div>
</template>

<style>
.account-picture {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #c5c9c9;
}
</style>

<script>
export default {
  name: 'User-Accounts',
  data() {
    return {
      errorMessage: null,
      errorMessageAccount: null,
      isRefreshing: false,
      isUpdatingAccount: false,
      activeAccounts: [],
      deactivatedAccounts: [],
      selectedAccount: {},
      showAccount: false
    }
  },
  methods: {
    //--------------------------------------------------------------------------
    clickedAccount(account) {
      if (!this.isAdmin()) {
        return;
      }
      this.selectedAccount = account;
      this.showAccount = true;
    },
    //--------------------------------------------------------------------------
    clickedDeactivate(account, deactivate) {
      if (this.isUpdatingAccount) {
        return;
      } else {
        this.isUpdatingAccount = true;
        this.errorMessageAccount = null;
      }

      var body = JSON.stringify({
        account: account.uuid,
        deactivate: deactivate
      });
      var that = this;
      var method = "POST";
      var url = "https://auth.platform.robocalls.ai/account/deactivate";
      var requiresSession = true;
      RobocallsAI.shared().execute(method, url, body, requiresSession, {
        onSuccess: function(code, body) {
          // Code = HTTP status code
          // Body = Response from server
          that.isUpdatingAccount = false;
          if (code != 200) {
            // Something went wrong
            var message = that.parseMessage(body);
            if (message) {
              that.errorMessageAccount = message;
            } else {
              that.errorMessageAccount = "Received a code " + code + " from the service";
            }
          } else {
            account.is_deactivated = deactivate;
            that.refresh();
          }
        },
        onCancelled: function() {
          // Fired if the request was cancelled
          that.isUpdatingAccount = false;
        },
        onFailure: function(error) {
          // Fired if the request fails
          that.isUpdatingAccount = false;
          that.errorMessageAccount = error;
        }
      })
    },
    //--------------------------------------------------------------------------
    clickedMakeAdmin(account, makeAdmin) {
      if (this.isUpdatingAccount) {
        return;
      } else {
        this.isUpdatingAccount = true;
        this.errorMessageAccount = null;
      }

      var body = JSON.stringify({
        account: account.uuid,
        admin: makeAdmin
      });
      var that = this;
      var method = "POST";
      var url = "https://auth.platform.robocalls.ai/account/admin";
      var requiresSession = true;
      RobocallsAI.shared().execute(method, url, body, requiresSession, {
        onSuccess: function(code, body) {
          // Code = HTTP status code
          // Body = Response from server
          that.isUpdatingAccount = false;
          if (code != 200) {
            // Something went wrong
            var message = that.parseMessage(body);
            if (message) {
              that.errorMessageAccount = message;
            } else {
              that.errorMessageAccount = "Received a code " + code + " from the service";
            }
          } else {
            account.is_admin = makeAdmin;
            that.refresh();
          }
        },
        onCancelled: function() {
          // Fired if the request was cancelled
          that.isUpdatingAccount = false;
        },
        onFailure: function(error) {
          // Fired if the request fails
          that.isUpdatingAccount = false;
          that.errorMessageAccount = error;
        }
      })
    },
    //--------------------------------------------------------------------------
    parseMessage(body) {
      try {
        var result = JSON.parse(body);
        return result.message;
      } catch (err) {
        return "";
      }
    },
    //--------------------------------------------------------------------------
    processAccounts(response) {

      // Make sure we have data
      if (!response || !response.accounts) {
        this.errorMessage = "No accounts were returned";
        this.accounts = [];
        return;
      }


      var active = [];
      var deactivated = [];
      for (var x = 0; x < response.accounts.length; x++) {
        if (response.accounts[x].is_deactivated) {
          deactivated.push(response.accounts[x]);
        } else {
          active.push(response.accounts[x]);
        }
      }
      this.activeAccounts = active;
      this.deactivatedAccounts = deactivated;

    },
    //--------------------------------------------------------------------------
    refresh: function() {
      // Make sure we're not currently refreshing
      if (this.isRefreshing) {
        return;
      } else {
        this.errorMessage = null;
        this.isRefreshing = true;
      }

      // Fetch the accounts data
      var that = this;
      var method = "GET";
      var url = "https://auth.platform.robocalls.ai/accounts/users";
      var requiresSession = true;
      RobocallsAI.shared().execute(method, url, null, requiresSession, {
        onSuccess: function(code, body) {
          // Code = HTTP status code
          // Body = Response from server
          that.isRefreshing = false;
          if (code != 200) {
            // Something went wrong
            var message = that.parseMessage(body);
            if (message) {
              that.errorMessage = message;
            } else {
              that.errorMessage = "Received a code " + code + " from the service";
            }
          } else {
            // Parse the accounts
            try {
              var result = JSON.parse(body);
              that.processAccounts(result);
            } catch (err) {
              that.errorMessage = "Failed to parse response from the server";
            }
          }
        },
        onCancelled: function() {
          // Fired if the request was cancelled
          that.isRefreshing = false;
        },
        onFailure: function(error) {
          // Fired if the request fails
          that.isRefreshing = false;
          that.errorMessage = error;
        }
      })
    },
    //--------------------------------------------------------------------------
    isAdmin() {
      var accountInfo = RobocallsAI.shared().accountInfo();
      if (accountInfo.role == "admin") {
        return true;
      } else {
        return false;
      }
    }
  },
  mounted() {
    this.refresh();
  },
}
</script>
